<template>
  <div class="product-info">
    <div class="is-size-5 is-headline has-letter-spacing">{{ activeProduct.name }}</div>
    <div class="gap" />
    <read-more
      class="read-more"
      more-str="Mehr Infos"
      :text="activeProduct.description"
      less-str="Weniger Infos"
      :max-chars="80"
    />
    <div class="gap" />
    <Ingredients
      :product="activeProduct"
    />

    <PriceRules />
  </div>
</template>

<script>
import Ingredients from '@/components/product-info/Ingredients'
import PriceRules from '@/components/product-info/PriceRules'
export default {
  components: { Ingredients, PriceRules},
}
</script>

<style lang="css" scoped>
</style>
