<template>
  <div id="app" class="app">
    <Navbar />
    <div v-if="!loadingFinished">
      <div class="container">
        Lade Konfigurator…
      </div>
    </div>
    <div v-else>
      <div class="container">
        <div class="columns" :class="{ 'is-flex' : isMobile }">
          <div class="column is-4">
            <Preview />
          </div>
          <div class="column is-8" :class="{ 'is-menu' : isMobile }">
            <MainMenue />
            <div class="wrap" v-if="!isMobile">
              <Content />
            </div>
          </div>
        </div>
        <div class="wrap-mobile" v-if="isMobile">
          <Content />
        </div>
      </div>
       <div v-if="isMobile">
          <FooterButtons />
        </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import MainMenue from '@/components/MainMenue'
import Content from '@/components/Content'
import Footer from '@/components/Footer'
import Preview from '@/components/Preview'
import FooterButtons from '@/components/FooterButtons'
import { EventBus } from '@/event-bus'

export default {
  components: { Navbar, MainMenue, Content, Footer, Preview, FooterButtons },
  data () {
    return {
      configuratorMounted: false
    }
  },
  computed: {
    categories () {
      return this.$store.state.categories
    },
    products () {
      return this.$store.state.products
    },
    labels () {
      return this.$store.state.labels
    },
    loadingFinished () {
      return this.categories.length > 0 && this.products.length > 0 && this.labels.length > 0
    },
    editMode () {
      return this.$store.state.editMode
    },
    showMenu () {
      if (this.isMobile && this.editMode) {
        return false
      }
      return true
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  mounted () {
    //window.onbeforeunload = function() { return "Deine Änderungen gehen verloren!" }
    this.$store.dispatch('setCategories')
    this.$store.commit('setWindowSize', this.getWidth())
    EventBus.$on('configuratorMounted', this.setConfiguratorMounted)
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    setConfiguratorMounted (val) {
      this.configuratorMounted = val
    },
    checkUrlParamsForDeepLinking () {
      const url = new URL(window.location.href)
      let productId = url.searchParams.get("productId")
      let labelId = url.searchParams.get("labelId")
      if (productId) {
        this.activeProduct = this.products.find(product => product.id === productId)
        this.productCategory = this.categories.find(cat => cat.id === this.activeProduct.categoryId.toString())
      }

      if (labelId) {
        this.currentStep = 'chooseText'
        this.activeLabel = this.labels.find(label => label.id === labelId)
      }
    }
  },
  watch: {
    products () {
      if (this.products.length > 0) {
        this.activeProduct = this.products[0]
      }
    },
    configuratorMounted () {
      if (this.configuratorMounted) {
        this.checkUrlParamsForDeepLinking()
      }
    }
  }
}
</script>

<style lang="sass">
.wrap
  min-height: 60vh
  margin: 0 20px 0 20px

.wrap-mobile
  min-height: 60vh
  margin: 0 20px 0 20px

.is-menu
  max-width: 25%!important
  margin-right: 5%!important

</style>
