// Axios Config

var axios = require('axios')
// TODO: Outsource in dotenv => https://www.npmjs.com/package/dotenv
//const domain = 'http://localhost:3000/v1'
const domain = 'https://api.pocket-rocket.io/v1'

const username = "pocketrocket"
const password = "83424hui23!!dd"
const token = `${username}:${password}`
const encodedToken = Buffer.from(token).toString('base64')

var axiosInstance = axios.create({
  baseURL: `${domain}`,
  headers: { 'Authorization': `Basic ${encodedToken}` }
});

module.exports = axiosInstance
