<template>
  <div>
    <div :class="['button is-fullwidth is-primary', { 'is-loading' : loading }]" @click="addToCart()">
      <span>In den Warenkorb</span>
    </div>
  </div>

</template>

<script>
import client from '@/client'
// import axios from 'axios'
export default {
  data () {
    return {
      loading: false
    }
  },
  computed: {
    qtyMainProduct () {
      return this.$store.state.qtyMainProduct
    },
    activeProduct () {
      return this.$store.state.activeProduct
    },
    canvas () {
      return this.$store.state.canvas
    }
  },
  methods: {
    async addToCart () {
      this.loading = true
      this.canvas.discardActiveObject().renderAll()

      let base64DataUrl = this.canvas.toDataURL({
        format: 'jpeg',
        quality: 1,
        multiplier: 4
      })

      const obj = this

      try {
        const blob = await (await fetch(base64DataUrl)).blob()
        if (blob) {
          const formData = new FormData()
          formData.append('file', blob, 'label.jpeg')
          formData.append('tag', 'label')
          formData.append('rotate', 270)

          obj.sendToServer(formData)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async sendToServer(formData) {
      try {
        const res = await client.post('/public/uploads', formData)
        if (res && res.data && res.data.resource) {
          this.loading = true
          const blobKey = res.data.resource.blob_key
          console.log(res.data.resource)
          this.sendToCart(blobKey)
        }
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    async sendToCart(blobKey) {
      try {
        let productId = this.activeProduct.id
        let quantity = this.qtyMainProduct

        const urlPart = `add-to-cart=${productId}&quantity=${quantity}&label_id=${blobKey}`
        window.location.href = `https://braufabrik.de/warenkorb/?${urlPart}`

        //
        // const url = `https://braufabrik.de/?${urlPart}`
        //
        // const res = await axios.get(url)
        // if (res) {
        //   window.location.href = `https://braufabrik.de/?${urlPart}`
        // }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
