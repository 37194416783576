<template>
  <div class="choose-product">
    <div class="is-size-4 is-headline has-letter-spacing" ref="selectProduct">Produkt auswählen:</div>
    <div class="columns is-multiline">
      <div class="column has-balanced-height is-3 is-clickable" v-for="(category, index) in filteredCategories" :key="index" align="center" @click="setProductCategory(category),scrollToTop()">
        <div class="product" :class="[(category.id === '140' ? 'no-display' : 'has-background-white')]">
          <img :src="getPreviewImage(category.name)" class="image" />
          <div class="is-headline">{{ category.name }}</div>
          <div class="claim">{{ category.description }}</div>
          <div class="select-product">
            <div v-if="category.id === '140'" :class="[(category.id === '58' ? 'is-clickable' : 'is-disabled is-no-available')]" class="button choose-product-cta">Bearbeitung</div>
            <div v-else-if="productCategory && (productCategory.id === category.id)" class="button choose-product-cta selected is-ocean">Ausgewählt</div>
            <div v-else @click="setProductCategory(category),scrollToTop()" class="button choose-product-cta">Auswählen</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ReadMore from 'vue-read-more'

import Vue from 'vue'
Vue.use(ReadMore)
export default {
  computed: {
    categories () {
      return this.$store.state.categories
    },
    products () {
      return this.$store.state.products
    },
    filteredCategories () {
      let categories = []
      this.categories.forEach(cat => {
        if (this.hasProductsInCategory(cat.id)) {
          categories.push(cat)
        }
      })
      return categories
    }
  },
  mounted () {
    if (!this.productCategory) {
      this.productCategory = this.filteredCategories[0]
    }
    if (this.isMobile) {
      this.scrollToProduct()
    }
  },
  methods: {
    hasProductsInCategory (categoryId) {
      return this.products.filter(prod => prod.categoryId === parseInt(categoryId)).length > 0
    },
    setProductCategory (category) {
      this.productCategory = category
      this.activeLabel = null
    },
    getPreviewImage (categoryName) {
      return require(`@/assets/images/preview_${categoryName}.png`)
    },
    scrollToProduct () { 
    const scrollMe = this.$refs.selectProduct
      if (scrollMe) {
        scrollMe.scrollIntoView({ block: 'start', behavior: 'smooth'});
      }
    }
  },
  watch: {
    activeProduct () {
      this.activeLabel = null
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.column.has-balanced-height
  display: flex

.choose-product
  margin-top: $base-gap * 3
  .choose-product-cta
    width: 130px
    margin-top: $base-gap
    &.selected
      cursor: default

  .category-headline
    background: $lightgrey
    text-align: center
    padding: $base-gap 0
  .product
    cursor: pointer
    .is-headline
      font-size: 22px
    .claim
      padding: 10px
  
.select-product
  padding-bottom: $base-gap * 3

.no-display
  display: none
</style>
