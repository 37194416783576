<template>
  <div class="bottle preview">
    <img :src="activeProduct.imagePreview" ref="bottle" @load="onBottleLoad" />
    <div id="canvas-wrapper" :style="{ bottom: `${activeProduct.positionFromBottom}px` }">
      <canvas
        id="productConfigurator"
        :width="labelWidth"
        :height="labelHeight"
      ></canvas>
      <div v-if="!activeLabel" class="dummy-image">
        <img :style="{ width: `${bottleWidth}px`}" src="https://braufabrik.de/wp-content/uploads/2022/02/label-dummy.png" />
      </div>
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric'
export default {
  data () {
    return {
      labelWidth: 0,
      labelHeight: 0,
      bottleWidth: 0
    }
  },
  computed: {
    canvas: {
      get () {
        return this.$store.state.canvas
      },
      set (val) {
        this.$store.commit('setCanvas', val)
      }
    },
  },
  mounted () {
    this.canvas = new fabric.Canvas('productConfigurator', { preserveObjectStacking: true })
  },
  methods: {
    onBottleLoad () {
      this.bottleWidth = this.$refs.bottle.clientWidth
      const relation = this.bottleWidth / this.activeProduct.labelWidth
      this.labelWidth = ((this.activeProduct.labelWidth * relation) - 16)
      this.labelHeight = ((this.activeProduct.labelHeight * relation) - 20)
      this.canvas.setHeight(this.labelHeight)
      this.canvas.setWidth(this.labelWidth)
    },
  },
}
</script>

<style lang="sass" scoped>
.bottle
  position: relative
  background: transparent
  overflow: hidden
 
  #canvas-wrapper
    position: absolute
    left: 8px
    border-radius: 300px/10px
    background: transparent
    overflow: hidden
    .dummy-image
      position: absolute
      top: 0
</style>
