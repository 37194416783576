<template>
  <div class="choose-product-type">
      <div class="is-size-4 is-headline has-letter-spacing" ref="selectProductType">{{ productCategory.name }} auswählen:</div>
      <div v-for="(product, index) in productsForCategory" :key="index" class="columns has-background-white product-detail">
        <div class="choose-product-claim" v-if="product.chooseProductClaim">{{ product.chooseProductClaim }}</div>
        <div class="ingredients">
          <Ingredients
            :product="product"
          />
        </div>
        <div class="column is-3" @click="setActiveProduct(product),scrollToTop()">
          <img :class="[(product.deliveryTime === 'Auf Lager' ? 'is-clickable' : '')]" :src="product.image" />
        </div>
        <div class="column is-9">
          <div class="is-headline">{{ product.name }}</div>
            <div class="columns is-mobile">
            <div v-if="product.deliveryTime" class="column is-narrow">
              <img v-if="product.deliveryTime !== 'Auf Lager'" src="@/assets/images/icon_not_avaiable.png" />
              <img v-else src="@/assets/images/icon_available.svg" />
            </div>
            <div v-if="product.deliveryTime" class="column is-narrow"> 
              <span :class="[(product.deliveryTime !== 'Auf Lager' ? 'is-text-red' : 'is-text-green')]" class="is-text-green">{{ product.deliveryTime }}</span>
            </div>
            <div class="column is-narrow">
              <img src="@/assets/images/icon_deliverable.svg" />
            </div>
            <div class="column is-narrow">
              <span class="info">Aktuell 6-10 Werktage</span>
            </div>
          </div>
          <read-more
            class="read-more"
            more-str="Mehr Infos"
            :text="product.description"
            less-str="Weniger Infos"
            :max-chars="50"
          />
          <div class="columns is-mobile">
          <div class="column" :class="{ 'is-offset-one-quarter' : isMobile }">
            <div v-if="product.deliveryTime !== 'Auf Lager'" class="button is-no-available is-disabled" disabled>nicht verfügbar</div>
            <div v-else-if="activeProduct.id === product.id" class="button choose-product-cta selected is-ocean">Ausgewählt</div>
            <div v-else @click="setActiveProduct(product),scrollToTop()" class="button choose-product-cta">Auswählen</div>
          </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import ReadMore from 'vue-read-more'
import Ingredients from '@/components/product-info/Ingredients'
import Vue from 'vue'
Vue.use(ReadMore)
export default {
  components: { Ingredients },
  computed: {
    products () {
      return this.$store.state.products
    },
    filteredCategories () {
      let categories = []
      this.categories.forEach(cat => {
        if (this.hasProductsInCategory(cat.id)) {
          categories.push(cat)
        }
      })
      return categories
    },
    productsForCategory () {
      if (this.productCategory) {
        return this.products.filter(prod => prod.categoryId === parseInt(this.productCategory.id)).sort((x,y) => {
          return x.menuOrder - y.menuOrder  })
      }  
      return []
    }
  },
  mounted () {
      if ((parseInt(this.activeProduct.categoryId) !== parseInt(this.productCategory.id)) && this.productsForCategory.length > 0) {
          this.activeProduct = this.productsForCategory[0]
      }
      if (this.productsForCategory[0].deliveryTime !== 'Auf Lager') {
            this.activeProduct = this.productsForCategory[+1]
          }
      if (this.isMobile) {
        this.scrollToType()
      }
  },
  methods: {
    hasProductsInCategory (categoryId) {
      return this.products.filter(prod => prod.categoryId === parseInt(categoryId)).length > 0
    },
    setActiveProduct (product) {
      if (product.deliveryTime === 'Auf Lager') {
      this.activeProduct = product
      this.activeLabel = null
      } else {
      alert("Leider ist dieses Produkt zur Zeit nicht verfügbar")
        return
      }
    },
    scrollToType () {
    const scrollMe = this.$refs.selectProductType
      if (scrollMe) {
        scrollMe.scrollIntoView({ block: 'start', behavior: 'smooth'});
      }
    }
  },
  watch: {
    activeProduct () {
      this.activeLabel = null
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.column.has-balanced-height
  display: flex

.choose-product-type
  margin-top: $base-gap * 3
  .choose-product-cta
    width: 130px
    margin-top: $base-gap
    &.selected
      cursor: default
  .product-detail
    margin-top: 10px
    position: relative
    img
      width: 100%

    .choose-product-claim
      position: absolute
      top: 0
      right: 0
      background: black
      color: white
      padding: 5px
      width: 110px
      font-size: 12px
      text-align: center

    .ingredients
      position: absolute
      bottom: 3px
      right: 3px

.is-disabled
  background-color: #0d0d0d!important
</style>
