<template>
  <div :class="[{ 'is-mobile' : isMobile }]">
    <ChooseProduct
      v-if="currentStep === 'chooseProduct'"
    />
    <ChooseProductType
      v-if="currentStep === 'chooseProductType'"
    />
    <ChooseDesign
      v-if="currentStep === 'chooseDesign'"
    />
    <Configurator />
  </div>
</template>

<script>
import ChooseProduct from '@/components/ChooseProduct'
import ChooseProductType from '@/components/ChooseProductType'
import ChooseDesign from '@/components/ChooseDesign'
import Configurator from '@/components/Configurator'
export default {
  components: { ChooseProduct, ChooseProductType, ChooseDesign, Configurator },
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.content
  padding: 0 $base-gap
</style>
