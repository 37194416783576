<template>
    <div class="columns is-mobile">
        <div class="column is-9 is-offset-3 is-sticky">
            <Navigation />
        </div>
    </div>
</template>
<script>
import Navigation from '@/components/Navigation'
export default {
 components: { Navigation }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.is-sticky
  position: fixed
  bottom: 0
  z-index: 100
  padding-bottom: 30px
  padding-left: 20px
</style>

