import Vue from 'vue'
import App from './App.vue'
import store from "@/store"
import global from '@/plugins/global'
Vue.mixin(global)

import '@/assets/sass/main.sass'

Vue.config.productionTip = false

import vClickOutside from 'v-click-outside'
Vue.directive('click-outside', vClickOutside.directive)

new Vue({
  store,
  render: h => h(App),
}).$mount('#app')
