<template>
  <div class="columns is-multiline">
    <div class="column is-8" v-if="currentStep === 'chooseText'" ref="selectText">
      <div v-for="(textObject, index) in allText" :key="index">
        <div class="gap" />
        <div class="is-size-4 is-headline has-letter-spacing">Text konfigurieren:</div>
        <div class="textbox" v-if="textObject === activeObject">
          <textarea autofocus placeholder="Mein Text" v-model="textObject.text" @keyup="updateCanvas()" class="text-input" />
          <div class="columns">
            <div class="column is-7">
              <div class="select-custom is-clickable">
                <div @click="fontSelectBoxOpen = true" :style="{ fontFamily: textObject.fontFamily }">{{ textObject.fontFamily }}</div>
                <div class="chevron" @click="fontSelectBoxOpen = true"><img src="@/assets/images/icon_chevron_down.svg" /></div>
                <div class="select-box" v-if="fontSelectBoxOpen" v-click-outside="closeFontSelectBox">
                  <div class="item is-clickable" v-for="(font, index) in fonts" :key="index" :style="{ fontFamily: font }" @click="setFontFamily(textObject, font); fontSelectBoxOpen = false;">{{ font }}</div>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div class="select-custom is-clickable">
                <div @click="fontSizeBoxOpen = true">{{ textObject.fontSize }}px</div>
                <div class="chevron" @click="fontSizeBoxOpen = true"><img src="@/assets/images/icon_chevron_down.svg" /></div>
                <div class="select-box" v-if="fontSizeBoxOpen" v-click-outside="closeFontSizeBox">
                  <div class="item is-clickable" v-for="(i, index) in 50" :key="index" :value="i" @click="textObject.fontSize = i; fontSizeBoxOpen = false;">{{ i }}px</div>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-mobile">
            <div class="column is-narrow">
              <div :class="['font-button', { 'is-active' : textObject.fontWeight === 'bold'}]" @click="toggleFontWeight(textObject)">f</div>
            </div>
            <div class="column is-narrow">
              <div :class="['font-button', { 'is-active' : textObject.fontStyle === 'italic'}]" @click="toggleFontStyle(textObject)"><i>k</i></div>
            </div>
            <div class="column is-narrow">
              <div :class="['font-button', { 'is-active' : textObject.underline === true}]" @click="toggleUnderline(textObject)"><u>u</u></div>
            </div>
            <div class="column is-narrow">
              <div class="font-button" @click="centerObject(textObject)">
                <img src="@/assets/images/icon_center.svg">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-narrow">
              <div>Textfarbe:</div>
              <div class="font-button" :style="{ background: textObject.fill}" @click="fontColorBoxOpen = true" />
              <div v-if="fontColorBoxOpen" class="font-color-box" v-click-outside="closeFontColorBox">
                <color-picker @input="updateTextColor" v-model="textObject.fill" />
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column"></div>
            <div class="column is-narrow">
              <div class="button is-rose" @click="removeObject(textObject)">Text löschen</div>
            </div>
          </div>
        </div>
        <div v-else class="textbox is-clickable">
          <div class="columns is-vcentered">
            <div class="column" @click="activeObject = textObject">
              {{ textObject.text }}
            </div>
            <div class="column is-narrow">
              <div class="button is-rose" @click="removeObject(textObject)">Text löschen</div>
            </div>
          </div>
        </div>
      </div>
      <div class="gap"/>
      <div class="is-size-4 is-headline has-letter-spacing">Text hinzufügen:</div>
      <div class="columns is-gapless is-flex is-multiline is-mobile">
        <div class="column is-2">
          <img class="is-clickable" src="@/assets/images/icon_add.svg" @click="addNewText()" />
        </div>
        <div class="column">
          <div class="is-clickable button-new-text" @click="addNewText()">Neuen Text hinzufügen</div>
        </div>
      </div>
    </div>
    <div class="column is-8-tablet is-11-mobile" v-if="currentStep === 'chooseImage'">
      <div class="gap" />
      <div class="is-size-4 is-headline has-letter-spacing">Optional: Bild konfigurieren</div>
      <div class="textbox" >
        <div class="is-size-5">
          Bild hochladen
        </div>
        <div>
          <file-selector
            accept-extensions=".jpg,.png,.jpeg"
            :multiple="false"
            :max-file-size="5 * 1024 * 1024"
            @changed="handleImageChanged"
          >
            <div class="file-selector">Bild auswählen oder hineinziehen</div>
          </file-selector>
          <p class="is-size-7">*Mit dem Hochladen eines Bildes erklärst Du dich mit den Bestimmungen zur Nutzung von Bildern einverstanden. Details zum Copyright findest Du hier in unseren <a href="https://braufabrik.de/agb-widerruf/" target="_blank">AGB</a>.</p>
        </div>
      </div>
      <div class="gap" />
      <div class="is-size-5 is-headline has-letter-spacing is-configuration">Alle Bilder:</div>
      <div v-for="(imageObject, index) in allImages" :key="index">
        <div class="gap" />
        <div class="textbox is-clickable">
          <div class="columns is-vcentered">
            <div class="column" @click="activeObject = imageObject">
              <img :src="imageObject.getSrc()" class="preview-image" />
              <div @click="bringObjectToFront(imageObject)">Nach vorne holen</div>
              <div @click="sendObjectToBack(imageObject)">Nach hinten stellen</div>
            </div>
            <div class="column is-narrow">
              <div class="button is-rose" @click="removeObject(imageObject)">Bild löschen</div>
            </div>
          </div>
        </div>
        <div class="gap" />
      </div>
    </div>

    <div class="column is-9 checkout is-12-mobile" ref="sumary" v-if="currentStep === 'chooseQty'">
      <div class="gap" />
      <div class="is-size-4 is-headline has-letter-spacing">Zusammenfassung:</div>
      <div class="gap" />
      <ProductInfo />
    </div>
  </div>
</template>

<script>
import { fabric } from 'fabric'
import { Chrome } from 'vue-color'
import { EventBus } from '@/event-bus'

import Vue from 'vue'
import FileSelector from 'vue-file-selector'
Vue.use(FileSelector)

import ProductInfo from '@/components/ProductInfo'
export default {
  components: {
    ProductInfo,
    'color-picker': Chrome
  },
  data () {
    return {
      canvasObjects: [],
      textboxOpen: true,
      imageboxOpen: false,
      activeObject: undefined,
      fontSelectBoxOpen: false,
      fontColorBoxOpen: false,
      fontSizeBoxOpen: false,
      fonts: [
        'Raleway',
        'Anton',
        'Noto Serif JP',
        'Glory',
        'Happy Monkey',
        'Pattaya',
        'Dancing Script',
        'Indie Flower',
        'Chewy',
        'Mouse Memoirs',
        'Wendy One',
        'Ribeye Marrow'
      ],
    }
  },
  computed: {
    activeLabel () {
      return this.$store.state.activeLabel
    },
    //TODO: filter if label is in category (123) and change the text color to white
   labelCategories () {
      return this.$store.state.categories
    },
    editMode () {
      return this.$store.state.editMode
    },
    canvas: {
      get () {
        return this.$store.state.canvas
      },
      set (val) {
        this.$store.commit('setCanvas', val)
      }
    },
    allText () {
      return this.canvasObjects.filter(obj => obj.type === 'i-text')
    },
    allImages () {
      return this.canvasObjects.filter(obj => obj.type === 'image' && obj.name !== 'template_img')
    }
  },
  mounted () {
    EventBus.$emit('configuratorMounted', true)

    this.canvas.setBackgroundColor('#FFFFFF')

    this.canvas.controlsAboveOverlay = true

    EventBus.$on('addNewText', this.addNewTextFromMenu)
    EventBus.$on('addNewImage', this.addNewImage)

    this.canvas.on('mouse:down', (options) => {
      if (options.target) {
        this.imageboxOpen = false
        this.activeObject = options.target

        if (options.target.type === 'text') {
          this.imageboxOpen = false
          this.textboxOpen = true
        }
        if (options.target.type === 'image') {
          this.textboxOpen = false
          this.imageboxOpen = true
        }
      } else {
        this.activeObject = undefined
        this.textboxOpen = true
      }
    })

    // remove selected object on backspace but only if its not being edited
    const obj = this
    document.onkeydown = function(event) {
      if (!event.target.classList.contains('text-input')) {
        let key = event.keyCode || event.charCode
        if ( key == 8 && obj.activeObject && !obj.activeObject.isEditing) {
          obj.removeObject(obj.activeObject)
        }
      }
    }
  },
  methods: {
    addNewImage () {
      this.activeObject = undefined
      this.textboxOpen = false
      this.imageboxOpen = true
    },
    setBackgroundImage (obj, imageUrl) {
      fabric.Image.fromURL(imageUrl, (function(img) {
        img.scaleToWidth(obj.canvas.width);
        img.scaleToHeight(obj.canvas.height);
        img.name = 'template_img';
        //obj.canvas.setOverlayImage(img, obj.canvas.renderAll.bind(obj.canvas));
        obj.canvas.setBackgroundImage(img, obj.canvas.renderAll.bind(obj.canvas));

      }), {
        crossOrigin: 'anonymous'
      })
      return true
    },
    bringObjectToFront (obj) {
      this.canvas.bringToFront(obj)
      this.canvas.renderAll()
    },
    sendObjectToBack (obj) {
      this.canvas.sendToBack(obj)
      this.canvas.renderAll()
    },
    addNewTextFromMenu () {
      let hasText = false
      let text = null
      this.canvas.getObjects().forEach(obj => {
        if (obj.name === 'text') {
          hasText = true
          text = obj
        }
      })

      if (!hasText) {
        this.addNewText()
      } else {
        this.activeObject = text
        this.textboxOpen = true
        this.selectObjectOnCanvas(text)
      }
    },
    addNewText () {
      let text = new fabric.IText('Mein Text', {
        fontSize: 40,
        fontWeight: '',
        fontStyle: '',
        fontFamily: 'Raleway',
        top: 10,
        left: 0,
        fill: '#000000',
        name: 'text',
        underline: false,
        showColorPicker: false
      })

      this.canvas.add(text)
      text.centerH()
      text.centerV()
      this.selectObjectOnCanvas(text)
      text.setCoords()

      this.textboxOpen = true
      this.imageboxOpen = false
      this.canvas.bringToFront(text)
      this.canvasObjects.push(text)
      this.activeObject = text
      this.updateCanvas()
    },
    selectObjectOnCanvas (obj) {
      this.canvas.setActiveObject(obj)
      obj.setCoords()
      this.updateCanvas()
    },
    updateCanvas () {
      this.canvas.renderAll()
    },
    addImageToCanvas (imageUrl, imageName, ctx, isActive = true, selectable = true) {
      fabric.Image.fromURL(imageUrl, (function(img) {
        img.scaleToWidth(ctx.canvas.width)
        img.scaleToHeight(ctx.canvas.height)
        img.name = imageName
        ctx.canvas.add(img)
        ctx.canvas.sendToBack(img)
        ctx.selectObjectOnCanvas(img)
        img.setCoords()
        ctx.canvasObjects.push(img)
        ctx.activeObject = img
      }), {
        crossOrigin: 'anonymous',
        hasControls: isActive,
        evented: isActive,
        selectable: selectable,
      });
    },
    handleImageChanged (files) {
      let reader = new FileReader()
      let imageName = ''
      let obj = this
      if (files[0]) {
        imageName = files[0].name.slice(0, 50)
      }

      reader.readAsDataURL(files[0])
      reader.onload = () => {
        obj.addImageToCanvas(reader.result, imageName, obj, true, true)
      };
    },
    toggleFontWeight (obj) {
      if (obj.fontWeight === '') {
        obj.fontWeight = 'bold'
      } else {
        obj.fontWeight = ''
      }
      this.updateCanvas()
    },
    toggleFontStyle (obj) {
      if (obj.fontStyle === '') {
        obj.fontStyle = 'italic'
      } else {
        obj.fontStyle = ''
      }
      this.updateCanvas()
    },
    toggleUnderline (obj) {
      this.canvas.setActiveObject(obj)
      this.canvas.getActiveObject().set('underline', !obj.underline)

      this.updateCanvas()
    },
    centerObject (obj) {
      obj.centerH()
    },
    closeFontSelectBox () {
      this.fontSelectBoxOpen = false
    },
    closeFontColorBox () {
      this.fontColorBoxOpen = false
    },
    closeFontSizeBox () {
      this.fontSizeBoxOpen = false
    },
    setFontFamily (obj, font) {
      obj.fontFamily = font
      this.updateCanvas()
      this.fontSelectBoxOpen = false
    },
    updateTextColor (color) {
      this.canvas.setActiveObject(this.activeObject)
      this.canvas.getActiveObject().set('fill', color.hex)

      this.updateCanvas()
    },
    removeObject (obj) {
      this.activeObject = undefined
      this.canvas.remove(obj)
      this.textboxOpen = false
      this.imageboxOpen = false

      const index = this.canvasObjects.indexOf(obj)
      if (index > -1) {
        this.canvasObjects.splice(index, 1)
      }
    },
    duplicateObject (obj) {
      const ctx = this
      obj.clone(function(clone) {
        ctx.canvas.add(clone.set({
          left: obj.left + 10,
          top: obj.top + 10
        }))
      })
    },
    saveCanvas () {
      localStorage.setItem('__bf_stored_canvas', JSON.stringify(this.canvas.toJSON()))
    },
    loadCanvas () {
      const storedCanvas = localStorage.getItem('__bf_stored_canvas')
      if (storedCanvas) {
        this.canvas.loadFromJSON(JSON.parse(storedCanvas), this.canvas.renderAll.bind(this.canvas))
      }
    },
    closeBox (box) {
      if (box === 'text') {
        this.textboxOpen = false
      }
      if (box === 'image') {
        this.imageboxOpen = false
      }
      this.activeObject = undefined
      this.canvas.discardActiveObject().renderAll()
    },
    closeEditMode () {
      if (!this.imageboxOpen && !this.textboxOpen) {
        this.$store.commit('setEditMode', false)
      }
    },
    resetActiveObject () {
      this.activeObject = undefined
      this.canvas.discardActiveObject().renderAll()
    },
    changeLabel () {
      this.canvas.setBackgroundImage(null, this.canvas.renderAll.bind(this.canvas))

      if (this.activeLabel && this.activeLabel.hasTransparency) {
        this.addImageToCanvas(this.activeLabel.image, 'template_img', this, false, false)
      } else if (this.activeLabel) {
        this.setBackgroundImage(this, `${this.activeLabel.image}?${Math.random()}`)
      }

      this.canvas.getObjects().forEach(obj => {
        if (obj.name === 'template_img') {
          this.removeObject(obj)
        }
        if (obj.name === 'text') {
          obj.centerH()
          obj.centerV()
        }
      })
    }
  },
  // destroyed () {
  //   EventBus.$off('addNewText')
  // },
  watch: {
    activeObject () {
      if (this.activeObject) {
        if (this.activeObject.name === 'text') {
          this.textboxOpen = true
        }
      }
    },
    activeLabel () {
      this.changeLabel()
    },
    'activeObject.fontSize' () {
      this.updateCanvas()
    },
    imageboxOpen () {
      this.closeEditMode()
    },
    textboxOpen () {
      this.closeEditMode()
    },
    editMode () {
      if (!this.editMode && this.imageboxOpen) {
        this.closeBox('image')
      } else if (!this.editMode && this.textboxOpen) {
        this.closeBox('text')
      }
      this.closeEditMode()
    }
  }
}
</script>
<style lang="css" scoped>
.fade-enter-active {
  transition: opacity .7s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Chewy&family=Dancing+Script:wght@400;700&family=Glory:wght@400;700&family=Happy+Monkey&family=Indie+Flower&family=Mouse+Memoirs&family=Noto+Serif+JP:wght@400;700&family=Pattaya&family=Raleway:wght@400;700&family=Ribeye+Marrow&family=Wendy+One&display=swap')
.textbox
  background-color: white
  padding: $base-gap
  position: relative

  .close
    cursor: pointer
    font-size: 11px
    position: absolute
    right: 10px
    top: 10px

  .thumb
    width: 40%

  .file-selector
    margin-top: $base-gap
    border: 1px dashed black
    cursor: pointer
    padding: 50px
    text-align: center

  .text-input
    background-color: $lightgrey
    border: none
    height: 120px
    width: calc(100% - #{$base-gap})
    font-size: 20px
    padding: 10px
    margin-top: $base-gap
    margin-bottom: $base-gap

  .font-button
    width: 45px
    height: 45px
    cursor: pointer
    color: black
    padding: 8px 14px 15px 14px
    display: flex
    justify-content: center
    border: 2px solid black
    &.is-active
      color: black
      border: 2px solid $orange

  .font-color-box
    position: absolute

  .preview-image
    width: 50%

.button-new-text
  margin-left: 7px
  margin-top: 2px

.content.is-mobile .checkout
  margin-left: -58px
  padding: $base-gap

.is-configuration
  margin-top: -30px
  margin: 0 20px 0 20px
</style>
