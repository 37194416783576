<template lang="html">
  <div class="main-menue">
    <div class="columns">
      <div :class="['column menue-item is-clickable', {'is-active': currentStep == 'chooseProduct' }]" @click="currentStep = 'chooseProduct'">
        <img src="@/assets/images/icon_bottle.svg">
        <div>Produkt</div>
      </div>
      <div :class="['column menue-item is-clickable', {'is-active': currentStep == 'chooseProductType' }]" @click="currentStep = 'chooseProductType'">
        <img src="@/assets/images/icon_variety.svg">
        <div>Sorte</div>
      </div>
      <div :class="['column menue-item is-clickable', {'is-active': currentStep == 'chooseDesign' }]" @click="chooseDesign()">
        <img src="@/assets/images/icon_design.svg">
        <div>Design</div>
      </div>
      <div :class="['column menue-item is-clickable', {'is-active': currentStep == 'chooseText' }]" @click="addNewText()">
        <img src="@/assets/images/icon_text.svg">
        <div>Text</div>
      </div>
      <div :class="['column menue-item is-clickable', {'is-active': currentStep == 'chooseImage' }]" @click="addNewImage()">
        <img src="@/assets/images/icon_upload.svg">
        <div>Bild</div>
      </div>
      <div :class="['column menue-item is-clickable has-background-orange cta']" @click="deselectAndGoToQty()">
        <div>Konfiguration</div>
        <div>abschließen</div>
      </div>
    </div>
    <div :class="{ 'is-hidden' : isMobile }">
    <Navigation />
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus'
import Navigation from '@/components/Navigation'

export default {
  components: { Navigation },
  methods: {
    chooseDesign () {
      this.currentStep = 'chooseDesign'
    },
    addNewText () {
      if (!this.activeLabel) {
        alert("Bitte wähle zunächst ein Design!")
        return
      } else {
        this.currentStep = 'chooseText'
        EventBus.$emit('addNewText')
        this.$store.commit('setEditMode', true)
      }
    },
    addNewImage () {
      if (!this.activeLabel) {
        alert("Bitte wähle zunächst ein Design!")
        return
      } else {
        this.currentStep = 'chooseImage'
        EventBus.$emit('addNewImage')
        this.$store.commit('setEditMode', true)
      }
    },
    deselectAndGoToQty () {
      if (!this.activeLabel) {
        alert("Bitte wähle zunächst ein Design!")
        return
      } else {
        this.$store.commit('setEditMode', false)
        this.currentStep = 'chooseQty'
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.main-menue
  z-index: 101
  text-align: center
  margin-top: 12px
  font-size: 14px
  .menue-item
    background: black
    color: white
    border-left: 2px solid white
    padding: 20px 0
    img
      height: 25px
    &.is-active
      background: rgba(0,0,0,0.65)
    &.cta
      padding-top: 22px

</style>
