<template>
  <nav class="navbar has-background-white" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <a class="navbar-item" href="https://www.braufabrik.de">
          <img src="https://braufabrik.de/wp-content/uploads/2021/09/braufabrik_logo_schwarz.png">
        </a>
      </div>
      <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">
          <a class="navbar-item" href="https://braufabrik.de/shop/">
            Shop
          </a>
          <a class="navbar-item" href="https://braufabrik.de/werbegeschenke/">
            B2B
          </a>
        </div>
        <div class="navbar-end">
          <a class="navbar-item" href="https://braufabrik.de/warenkorb/" target="_blank">
            Warenkorb
          </a>
        </div>
      </div>

    </div>
  </nav>
</template>

<script>
export default {
 
}
</script>

<style lang="sass" scoped>
.navbar
  padding: 20px
  img
    margin-right: 100px
  a.navbar-item
    text-decoration: none
    font-weight: bolder
</style>
