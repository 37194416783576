<template>
  <div>
    <div class="is-clickable main" @click="incredientModalOpen = true">Zutatenliste</div>

    <Modal
      v-if="incredientModalOpen"
      @close="incredientModalOpen = false"
    >
      <div class="incredients">
        <strong>{{ product.name }}</strong>
        <div class="gap" />
        <div v-html="product.shortDescription" />
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  components: { Modal },
  props: {
    product: Object
  },
  data () {
    return {
      incredientModalOpen: false
    }
  }
}
</script>

<style lang="sass" scoped>
.incredients
  padding: 10px
.main
  font-size: 12px
</style>
