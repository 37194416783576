import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import client from '@/client'

export const store = new Vuex.Store({
  state: {
    categories: [],
    products: [],
    labels: [],
    activeProduct: null,
    productCategory: null,
    activeLabel: null,
    chooseProductModalOpen: false,
    chooseDesignModalOpen: false,
    windowSize: null,
    editMode: false,
    qtyMainProduct: 20,
    canvas: null,
    currentStep: 'chooseProduct'
  },
  mutations: {
    setProductCategory (state, val) {
      state.productCategory = val
    },
    setCurrentStep (state, val) {
      state.currentStep = val
    },
    setEditMode(state, val) {
      state.editMode = val
    },
    setWindowSize(state, size) {
      state.windowSize = size
    },
    setCategories(state, value) {
      state.categories = value
    },
    setProducts(state, value) {
      state.products = value
    },
    setLabels(state, value) {
      state.labels = value
    },
    setActiveProduct(state, value) {
      state.activeProduct = value
    },
    setActiveLabel(state, value) {
      state.activeLabel = value
    },
    setChooseProductModalOpen(state, value) {
      state.chooseProductModalOpen = value
    },
    setChooseDesignModalOpen(state, value) {
      state.chooseDesignModalOpen = value
    },
    setQtyMainProduct (state, value) {
      state.qtyMainProduct = value
    },
    setCanvas (state, value) {
      state.canvas = value
    }
  },
  actions: {
    async setCategories({commit, dispatch}) {
      try {
        let categories = []
        const res = await client.get(`/public/items/categories`)

        if (res.data) {
          res.data.resources.forEach(data => {
            const category = {
              id: data.platform_id,
              name: data.name,
              description: data.data.description,
              menuOrder: data.data.menu_order,
              parent: data.data.parent
            }
            categories.push(category)
          })
          commit('setCategories', categories)
          dispatch('setProducts')
          dispatch('setLabels')
        }
      } catch (error) {
        console.log(error)
      }

    },
    async setProducts({commit}) {
      try {
        let products = []
        const res = await client.get(`/public/items/products?per_page=10000`)

        if (res.data) {
          res.data.resources.forEach(data => {
            
            let deliveryTime = ''
            if (data.data.delivery_time) {
               deliveryTime = data.data.delivery_time.name
             }

            let image = undefined
            if (data.data.images.length > 0) {
              image = data.data.images[0].src
            }

            let imagePreview = undefined
            if (data.data.images.find(img => img.alt === 'preview')) {
              imagePreview = data.data.images.find(img => img.alt === 'preview').src
            }

            let imageZoom = undefined
            if (data.data.images.find(img => img.alt === 'zoom')) {
              imageZoom = data.data.images.find(img => img.alt === 'zoom').src
            }

            let categoryId = undefined
            if (data.data.categories.map(cat => cat.id)) {
              categoryId = data.data.categories.map(cat => cat.id)[0]
            }

            // dimensions
            let labelWidth = undefined
            if (data.data.attributes.filter(el => el.name === 'labelWidth')[0]) {
              labelWidth = parseInt(data.data.attributes.filter(el => el.name === 'labelWidth')[0].options[0])
            }

            let labelHeight = undefined
            if (data.data.attributes.filter(el => el.name === 'labelHeight')[0]) {
              labelHeight = parseInt(data.data.attributes.filter(el => el.name === 'labelHeight')[0].options[0])
            }

            let positionFromBottom = undefined
            if (data.data.attributes.filter(el => el.name === 'positionFromBottom')[0]) {
              positionFromBottom = parseInt(data.data.attributes.filter(el => el.name === 'positionFromBottom')[0].options[0])
            }

            // quantity and content
            let quantity = undefined
            if (data.data.meta_data.filter(el => el.key === '_unit_product')[0]) {
              quantity = parseInt(data.data.meta_data.filter(el => el.key === '_unit_product')[0].value)
            }

            let content = undefined
            if (data.data.meta_data.filter(el => el.key === '_unit_base')[0]) {
              content = parseFloat(data.data.meta_data.filter(el => el.key === '_unit_base')[0].value)
            }

            let unit = undefined
            if (data.data.attributes.filter(el => el.name === 'unitConfigurator')[0]) {
              unit = data.data.attributes.filter(el => el.name === 'unitConfigurator')[0].options[0]
            }

            let productClaim = undefined
            if (data.data.attributes.filter(el => el.name === 'productClaim')[0]) {
              productClaim = data.data.attributes.filter(el => el.name === 'productClaim')[0].options[0]
            }

            let maxQuantity = undefined
            if (data.data.attributes.filter(el => el.name === 'maxQuantity')[0]) {
              maxQuantity = data.data.attributes.filter(el => el.name === 'maxQuantity')[0].options[0]
            }

            let chooseProductClaim = undefined
            if (data.data.attributes.filter(el => el.name === 'chooseProductClaim')[0]) {
              chooseProductClaim = data.data.attributes.filter(el => el.name === 'chooseProductClaim')[0].options[0]
            }

            let deposit = undefined
            if (data.data.attributes.filter(el => el.name === 'deposit')[0]) {
              deposit = parseFloat(data.data.attributes.filter(el => el.name === 'deposit')[0].options[0])
            }

            // price table
            let priceRules = []
            if (data.data.meta_data.filter(el => el.key === '_fixed_price_rules')[0]) {
              let index = 0
              let upperTier = undefined
              const tiers = data.data.meta_data.filter(el => el.key === '_fixed_price_rules')[0].value

              // first row
              priceRules.push({"lowerTier": 1, "upperTier": parseInt(Object.keys(tiers)[index]), "price": parseFloat(data.data.price)})

              Object.keys(tiers).forEach(tier => {
                index += 1
                if (parseInt(Object.keys(tiers)[index])) {
                  upperTier = parseInt(Object.keys(tiers)[index])
                } else {
                  upperTier = parseInt(tier)
                }
                priceRules.push({"lowerTier": parseInt(tier), "upperTier": upperTier, "price": parseFloat(tiers[tier])})
              })
            }
            const product = {
              id: data.platform_id,
              name: data.name,
              description: data.data.description,
              shortDescription: data.data.short_description,
              image: image,
              imagePreview: imagePreview,
              imageZoom: imageZoom,
              categoryId: categoryId,
              menuOrder: data.data.menu_order,
              labelWidth: labelWidth,
              labelHeight: labelHeight,
              positionFromBottom: positionFromBottom,
              price: data.data.price,
              unit: unit,
              priceRules: priceRules,
              quantity: quantity,
              content: content,
              productClaim: productClaim,
              chooseProductClaim: chooseProductClaim,
              maxQuantity: maxQuantity,
              deposit: deposit,
              deliveryTime: deliveryTime
            }
            products.push(product)
          })
          commit('setProducts', products)
        }

      } catch (error) {
        console.log(error)
      }
    },
    async setLabels ({commit}) {
      try {
        let labels = []
        const res = await client.get(`/public/items/labels?per_page=10000`)

        if (res.data) {
          res.data.resources.forEach(data => {

            let image = undefined
            if (data.data.images.length > 0) {
              image = data.data.images[0].src
            }

            let imagePreview = undefined
            if (data.data.images.find(img => img.alt === 'preview')) {
              imagePreview = data.data.images.find(img => img.alt === 'preview').src
            }

            // transparency?
            let hasTransparency = undefined
            if (data.data.attributes.filter(el => el.name === 'hasTransparency')[0]) {
              hasTransparency = data.data.attributes.filter(el => el.name === 'hasTransparency')[0].options[0] === 'true'
            }

            const label = {
              id: data.platform_id,
              name: data.name,
              description: data.description,
              shortDescription: data.short_description,
              image: image,
              imagePreview: imagePreview,
              categoryIds: data.data.categories.map(cat => cat.id),
              menuOrder: data.data.menu_order,
              hasTransparency: hasTransparency
            }
            labels.push(label)
          })
          commit('setLabels', labels)
        }

      } catch (error) {
        console.log(error)
      }
    }
  }
})

export default store
