<template>
  <div class="choose-design">
    <div class="is-headline is-size-4 has-letter-spacing" ref="selectDesign">Kategorie auswählen</div>
    <div class="choose-design-box">
      <div class="select">
        <select v-model="labelCategory">
          <option value="all">Alle</option>
          <option v-for="(category, index) in labelCategories" :value="category" :key="index">{{ category.name }}</option>
        </select>
      </div>
      <div class="columns is-multiline is-mobile">
        <div v-for="(label, index) in labels" :key="index" @click="setActiveLabel(label), scrollToTop()" class="column is-2-tablet is-clickable is-3-mobile">
          <div :class="['image', { 'is-active' : activeLabel && (activeLabel.id === label.id) }]">
            <img v-if="label.imagePreview " :src="label.imagePreview" />
            <img v-else :src="label.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const labelCategoryId = 64
export default {
  data () {
    return {
      labelCategory: 'all'
    }
  },
  mounted () {
    this.scrollToDesign()
  },
  computed: {
    labelCategories () {
      return this.$store.state.categories.filter(cat => cat.parent === labelCategoryId)
    },
    labels () {
      if (this.labelCategory === 'all') {
        return this.$store.state.labels.filter(label => label.categoryIds.includes(this.activeProduct.categoryId)).sort((x,y) => {
          return x.menuOrder - y.menuOrder
          })
      } else {
        return this.$store.state.labels.filter(label => (label.categoryIds.includes(parseInt(this.labelCategory.id)) && label.categoryIds.includes(this.activeProduct.categoryId)))
      }
    }
  },
  methods: {
    setActiveLabel (label) {
      this.activeLabel = label
    },
    scrollToDesign () { 
    const scrollMe = this.$refs.selectDesign
      if (scrollMe) {
        scrollMe.scrollIntoView({ block: 'start', behavior: 'smooth'});
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.choose-design
  margin-top: $base-gap * 3
  scroll-margin-top: 100px
  .image
    cursor: pointer
    margin-top: $base-gap
    border: 4px solid white
    &.is-active
      border: 4px solid $orange
  .choose-design-box
    background: white
    padding: 10px


</style>
