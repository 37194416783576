<template>
    <div class="columns">
    <div class="column navigation has-margin" v-if="currentStep === 'chooseProduct'">
      <div class="buttons" :class="[{ 'is-pulled-right' : !isMobile }]">
          <button :class="[(activeProduct ? 'button is-active is-primary is-cornered' : 'button')]" @click="chooseProductType">Weiter <img :src="nextIcon" class="icon-navigation" /></button>
      </div>
    </div>
    <div class="column navigation" v-if="currentStep === 'chooseProductType'">
      <div class="buttons" :class="[{ 'is-pulled-right' : !isMobile }]">
          <button class="button" @click="chooseProduct">Zurück<img src="@/assets/images/icon_back.png" class="icon-navigation" /></button>
          <button :class="[(activeProduct ? 'button is-active is-primary is-cornered' : 'button')]" @click="chooseDesign">Weiter<img :src="nextIcon" class="icon-navigation" /></button>
      </div>
    </div>
    <div class="column navigation" v-if="currentStep === 'chooseDesign'">
      <div class="buttons" :class="[{ 'is-pulled-right' : !isMobile }]">
        <button class="button" @click="chooseProductType">Zurück<img src="@/assets/images/icon_back.png" class="icon-navigation" /></button>
        <button :class="[(activeLabel ? 'button is-active is-primary is-cornered' : 'button')]" @click="chooseText">Weiter<img :src="nextIconBlack" class="icon-navigation" /></button>
      </div>
    </div>
    <div class="column navigation" v-if="currentStep === 'chooseText'">
      <div class="buttons" :class="[{ 'is-pulled-right' : !isMobile }]">
        <button class="button" @click="chooseDesign">Zurück<img src="@/assets/images/icon_back.png" class="icon-navigation" /></button>
        <button :class="[(activeProduct ? 'button is-active is-primary is-cornered' : 'button')]" @click="chooseImage">Weiter<img :src="nextIcon" class="icon-navigation" /></button>
      </div>
    </div>
     <div class="column navigation" v-if="currentStep === 'chooseImage'">
      <div class="buttons" :class="[{ 'is-pulled-right' : !isMobile }]">
        <button class="button" @click="chooseText">Zurück<img src="@/assets/images/icon_back.png" class="icon-navigation" /></button>
        <button :class="[(activeProduct ? 'button is-active is-primary is-cornered' : 'button')]" @click="deselectAndGoToQty">Weiter<img :src="nextIcon" class="icon-navigation" /></button>
      </div>
    </div>
    <div class="column navigation" v-if="currentStep === 'chooseQty'">
      <div class="button" :class="[{ 'is-pulled-right' : !isMobile }]">
        <button class="button" @click="chooseImage">Zurück<img src="@/assets/images/icon_back.png" class="icon-navigation" /></button>
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from '@/event-bus'

export default {
 data () {
    return {
      message: '',
      iconNext: require('@/assets/images/icon_next.png') ,
      iconNextBlack: require('@/assets/images/icon_next_black.png'),
      iconBack: require('@/assets/images/icon_back.png')
    }
  },
 methods: {
    chooseProduct () {
      this.currentStep = 'chooseProduct'
    },
    chooseProductType () {
      this.currentStep = 'chooseProductType'
    },
    chooseDesign () {
      if (this.activeProduct.deliveryTime === 'Auf Lager') {
      this.currentStep = 'chooseDesign'
      }  else {
      alert("Das ausgewählte Produkt ist nicht verfügbar, bitte wählen Sie ein anderes Produkt")
        return
      }
    },
    chooseText () {
     if (!this.activeLabel) {
        alert("Bitte wähle zunächst ein Design!")
        return
      } else {
        EventBus.$emit('addNewText')
        this.$store.commit('setEditMode', true)
        this.currentStep = 'chooseText'
      }
    },
    chooseImage () {
      this.currentStep = 'chooseImage'
    },
    deselectAndGoToQty () {
      this.$store.commit('setEditMode', false)
      this.currentStep = 'chooseQty'
    },
    chooseQty () {
      this.currentStep = 'chooseQty'
    },
  }, 
  computed: {
    nextIcon (){
    if (!this.activeProduct && !this.activeLabel) {
      return this.iconNext
    } else {
      return this.iconNextBlack
    }
   },
   nextIconBlack (){
     if (!this.activeLabel) {
       return this.iconNext
     } else {
       return this.iconNextBlack
     }
   },
  }
}
</script>
<style lang="sass" scoped>
@import "~@/assets/sass/main.sass"
.information
  color: red
  padding-right: 30px
  font-size: 20px
  background-color: $lightgrey

.is-finish
  color: white
  background: $orange

.navigation
 padding: 10px 0 0 0
 
.button-nav
  z-index: 50
  background: black
  color: white
  cursor: pointer
  border: 1px solid black
  border-radius: 0
  text-align: center
  text-transform: uppercase
  padding: 13px 20px
  cursor: pointer

.has-margin
  margin-left: 38%

.icon-navigation
  padding-left: 5px
</style>