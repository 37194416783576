<template>
  <div class="footer">
    <div class="container">
      <div class="columns">
        <div class="column is-3">
          <img src="https://usercontent.one/wp/braufabrik.de/wp-content/uploads/2021/10/braufabrik_logo_weiss-510x90.png?media=1635925693" />

          <div class="link-block">
            <a href="https://braufabrik.de/impressum/">Impressum</a>
            <a href="https://braufabrik.de/agb-widerruf/">AGB/Widerruf</a>
            <a href="https://braufabrik.de/datenschutzerklaerung/">Datenschutz</a>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
}
</script>

<style lang="sass" scoped>
.link-block
  margin-top: 20px
  a
    display: block
    text-decoration: none
    color: white
    margin-bottom: 5px
    color: #f1f1f1
</style>
