const currency = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

const mobileBreakpoint = 778

export default {
  data () {
    return {
      currency: currency
    }
  },
  computed: {
    windowSize () {
      return this.$store.state.windowSize
    },
    isMobile () {
      return this.windowSize <= mobileBreakpoint
    },
    productCategory: {
      get () {
        return this.$store.state.productCategory
      },
      set (val) {
        this.$store.commit('setProductCategory', val)
      }
    },
    activeProduct: {
      get () {
        return this.$store.state.activeProduct
      },
      set (val) {
        this.$store.commit('setActiveProduct', val)
      }
    },
    activeLabel: {
      get () {
        return this.$store.state.activeLabel
      },
      set (val) {
        this.$store.commit('setActiveLabel', val)
      }
    },
    currentStep: {
      get () {
        return this.$store.state.currentStep
      },
      set (val) {
        this.$store.commit('setCurrentStep', val)
      }
    },
  },
  methods: {
    truncateString (str, num) {
      if (str) {
        if (str.length <= num) {
          return str
        }
        return str.slice(0, num) + '…'
      } else {
        return ''
      }
    },
    scrollToTop() {
      window.scrollTo({top:0, behavior: "smooth"})
    },
    // resizing
    handleResize () {
      this.$store.commit('setWindowSize', this.getWidth())
    },
    getWidth () {
      return Math.max(
        document.body.scrollWidth,
        document.documentElement.scrollWidth,
        document.body.offsetWidth,
        document.documentElement.offsetWidth,
        document.documentElement.clientWidth
      )
    }
  }
}
