<template>
  <div class="price-rules">
    <div class="rules">
      <div class="columns">
        <div class="column">
          <table cellpadding="4">
            <thead>
              <th align="left">Anzahl</th>
              <th class="price-value" align="left">Preis</th>
            </thead>
            <tbody>
              <tr v-for="(rule, index) in activeProduct.priceRules" :key="index">
                <td>ab {{ rule.lowerTier }} {{ unitParser(activeProduct.unit, rule.lowerTier) }}</td>
                <td class="price-value"><strong>{{ currency.format(rule.price) }}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="column is-narrow">
          <img class="free-shipping" src="https://braufabrik.de/wp-content/uploads/2022/02/Versandkostenfrei_ab_95_Euro.png" />
        </div>
      </div>
    </div>
    <div class="is-size-5 is-headline has-letter-spacing">Menge wählen:</div>
    <span class="info">Aktuelle Lieferzeit 6-10 Werktage</span>
    <div class="columns is-vcentered">
      <div class="column is-5">
        <div class="qty-field">
          <div class="select-custom is-clickable">
            <div @click.self="qtyBoxOpen = true">{{ qtyMainProduct }} {{ unitParser(activeProduct.unit, qtyMainProduct) }}</div>
            <div @click.self="qtyBoxOpen = true" class="chevron"><img @click.self="qtyBoxOpen = true" src="@/assets/images/icon_chevron_down.svg" /></div>
            <div class="select-box" v-if="qtyBoxOpen" v-click-outside="closeQtyBox">
              <div class="item is-clickable" v-for="(i, index) in maxQuantity" :key="index" :value="i" @click="setQuantity(i)">
                {{ i + 20}} {{ unitParser(activeProduct.unit, i) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <AddToCart />
      </div>
    </div>
    <div class="prices">
      <div class="is-size-4"><strong>{{ currency.format(totalPrice) }}</strong></div>
      <div class="is-size-7"><span>{{ currency.format(priceTierForQty) }}/{{ unitParser(activeProduct.unit, 1) }}</span>&nbsp;&nbsp;&nbsp;<span>{{ currency.format(basePrice) }}/Liter</span></div>
      <span v-if="activeProduct.deposit">Inkl. gesetzl. MwSt., zzgl. Pfand und Versandkosten</span>
      <span v-else>Inkl. gesetzl. MwSt., zzgl. Versandkosten</span>
    </div>

    <div class="is-size-7">
      <p>Willst Du größere Mengen bestellen? Schreib eine Mail an <a href="mailto:info@braufabrik.de">info@braufabrik.de.</a> Gerne unterbreiten wir Dir ein individuelles Angebot. </p>
    </div>
  </div>
</template>

<script>
import AddToCart from '@/components/AddToCart'
export default {
  components: { AddToCart },
  data () {
    return {
      qtyBoxOpen: false
    }
  },
  computed: {
    priceTierForQty () {
      const tier = this.activeProduct.priceRules.find(rule => (this.qtyMainProduct >= rule.lowerTier && this.qtyMainProduct < rule.upperTier) || (rule.lowerTier === rule.upperTier && this.qtyMainProduct >= rule.lowerTier))

      if (tier) {
        return tier.price
      } else {
        return 0.0
      }
    },
    totalPrice () {
      return this.priceTierForQty * this.qtyMainProduct
    },
    basePrice () {
      return this.totalPrice/(this.activeProduct.quantity * this.activeProduct.content * this.qtyMainProduct)
    },
    qtyMainProduct: {
      get () {
        return this.$store.state.qtyMainProduct
      },
      set (val) {
        this.$store.commit('setQtyMainProduct', val)
      }
    },
    maxQuantity () {
      if (this.activeProduct.maxQuantity) {
        return parseFloat(this.activeProduct.maxQuantity)
      }
      return 32
    }
  },
  methods: {
    unitParser (unit, qty) {
      if (unit === 'Kisten') {
        if (qty === 1) {
          return "Kiste"
        }
      }
      if (unit === 'Flaschen') {
        if (qty === 1) {
          return "Flasche"
        }
      }
      return unit
    },
    closeQtyBox () {
      this.qtyBoxOpen = false
    },
    setQuantity (qty) {
      this.qtyMainProduct = qty
      this.closeQtyBox()
    }
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/sass/main.sass"
.price-rules
  .rules
    border-top: 1px solid black
    border-bottom: 1px solid black
    margin: $base-gap 0
    padding: $base-gap 0
    .free-shipping
      width: 150px
      height: 150px
  .qty-field
    margin: $base-gap 0
    input
      border: 0
      height: 35px
      font-size: 20px
      width: 50px
      text-align: center

.prices
  padding: 0px 0 20px 0

.price-value
  padding-left: 20px
</style>
